/* Shader.svelte generated by Svelte v3.31.0 */
import {
	SvelteComponentDev,
	add_location,
	attr_dev,
	detach_dev,
	dispatch_dev,
	element,
	init,
	insert_dev,
	noop,
	safe_not_equal,
	validate_slots
} from "svelte/internal";

const file = "Shader.svelte";

function create_fragment(ctx) {
	let canvas;

	const block = {
		c: function create() {
			canvas = element("canvas");
			attr_dev(canvas, "id", "canvas-main");
			attr_dev(canvas, "width", /*canvasWidth*/ ctx[0]);
			attr_dev(canvas, "height", /*canvasHeight*/ ctx[1]);
			attr_dev(canvas, "class", "svelte-zcnnn5");
			add_location(canvas, file, 93, 0, 1791);
		},
		l: function claim(nodes) {
			throw new Error("options.hydrate only works if the component was compiled with the `hydratable: true` option");
		},
		m: function mount(target, anchor) {
			insert_dev(target, canvas, anchor);
		},
		p: function update(ctx, [dirty]) {
			if (dirty & /*canvasWidth*/ 1) {
				attr_dev(canvas, "width", /*canvasWidth*/ ctx[0]);
			}

			if (dirty & /*canvasHeight*/ 2) {
				attr_dev(canvas, "height", /*canvasHeight*/ ctx[1]);
			}
		},
		i: noop,
		o: noop,
		d: function destroy(detaching) {
			if (detaching) detach_dev(canvas);
		}
	};

	dispatch_dev("SvelteRegisterBlock", {
		block,
		id: create_fragment.name,
		type: "component",
		source: "",
		ctx
	});

	return block;
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	validate_slots("Shader", slots, []);
	let { controlsArray = [] } = $$props;
	let { shader } = $$props;
	let pixelRatio = 1 / 1; // 1/8 is faster
	let canvasWidth, canvasHeight;
	let controlUniforms = {};

	window.onload = function () {
		let mouseX = 0, mouseY = 0;

		document.addEventListener("mousemove", event => {
			mouseX = event.clientX;
			mouseY = event.clientY;
		});

		const regl = require("regl")({
			canvas: "#canvas-main",
			attributes: { preserveDrawingBuffer: true }
		});

		let setupQuad;
		let image = new Image();
		image.crossOrigin = "Anonymous";

		//image.src = 'https://dianov.org/media/neuroji-evilous.png'
		//image.src = './pol2.jpg'
		image.src = "./pol.jpg";

		//image.src = './test.png'
		image.onload = function () {
			let imageTexture = regl.texture(image);

			setupQuad = regl({
				frag: shader,
				vert: `precision mediump float;attribute vec2 position;varying vec2 uv;void main() {uv=position;gl_Position = vec4(position, 0, 1);}`,
				attributes: { position: [-4, -4, 4, -4, 0, 4] },
				uniforms: {
					...controlUniforms,
					texture: imageTexture,
					tick: regl.context("tick"),
					TIME: regl.context("time"),
					width: regl.context("viewportWidth"),
					height: regl.context("viewportHeight"),
					mouseX: () => mouseX,
					mouseY: () => mouseY
				},
				depth: { enable: false },
				count: 3
			});

			regl.frame(() => {
				setupQuad(() => {
					regl.draw();
				});
			});
		};

		resizeCanvas();
	};

	window.addEventListener("resize", function () {
		resizeCanvas();
	});

	function resizeCanvas() {
		$$invalidate(0, canvasWidth = document.documentElement.clientWidth * pixelRatio);
		$$invalidate(1, canvasHeight = document.documentElement.clientHeight * pixelRatio);
	}

	const writable_props = ["controlsArray", "shader"];

	Object.keys($$props).forEach(key => {
		if (!~writable_props.indexOf(key) && key.slice(0, 2) !== "$$") console.warn(`<Shader> was created with unknown prop '${key}'`);
	});

	$$self.$$set = $$props => {
		if ("controlsArray" in $$props) $$invalidate(2, controlsArray = $$props.controlsArray);
		if ("shader" in $$props) $$invalidate(3, shader = $$props.shader);
	};

	$$self.$capture_state = () => ({
		controlsArray,
		shader,
		pixelRatio,
		canvasWidth,
		canvasHeight,
		controlUniforms,
		resizeCanvas
	});

	$$self.$inject_state = $$props => {
		if ("controlsArray" in $$props) $$invalidate(2, controlsArray = $$props.controlsArray);
		if ("shader" in $$props) $$invalidate(3, shader = $$props.shader);
		if ("pixelRatio" in $$props) pixelRatio = $$props.pixelRatio;
		if ("canvasWidth" in $$props) $$invalidate(0, canvasWidth = $$props.canvasWidth);
		if ("canvasHeight" in $$props) $$invalidate(1, canvasHeight = $$props.canvasHeight);
		if ("controlUniforms" in $$props) controlUniforms = $$props.controlUniforms;
	};

	if ($$props && "$$inject" in $$props) {
		$$self.$inject_state($$props.$$inject);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*controlsArray*/ 4) {
			$: controlsArray.forEach(d => {
				controlUniforms[d.id] = () => d.value;
			});
		}
	};

	return [canvasWidth, canvasHeight, controlsArray, shader];
}

class Shader extends SvelteComponentDev {
	constructor(options) {
		super(options);
		init(this, options, instance, create_fragment, safe_not_equal, { controlsArray: 2, shader: 3 });

		dispatch_dev("SvelteRegisterComponent", {
			component: this,
			tagName: "Shader",
			options,
			id: create_fragment.name
		});

		const { ctx } = this.$$;
		const props = options.props || {};

		if (/*shader*/ ctx[3] === undefined && !("shader" in props)) {
			console.warn("<Shader> was created without expected prop 'shader'");
		}
	}

	get controlsArray() {
		throw new Error("<Shader>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set controlsArray(value) {
		throw new Error("<Shader>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	get shader() {
		throw new Error("<Shader>: Props cannot be read directly from the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}

	set shader(value) {
		throw new Error("<Shader>: Props cannot be set directly on the component instance unless compiling with 'accessors: true' or '<svelte:options accessors/>'");
	}
}

export default Shader;